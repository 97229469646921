import React from 'react';
import './App.css';

function App() {
  return (
    <div>

      <header className="App-header flex-column">
        <h2 className="author-name">
          Elowen Li
        </h2>
        <h5 className="author-subtitle">
          Romance and Fantasy Novelist
        </h5>

        <div>
          <nav id="top-menu-navigation">
            <ul className="flex-row">
              <li>
                <a href="#about">
                  ABOUT
                </a>
              </li>
              
              <li>
                <a href="#books">
                  BOOKS
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <body className="App-body">
        <section id="about" className="section-description">
          <h2>
            ABOUT
          </h2>
          <p>
            I'm Elowen Li, a romance and fantasy writer based in San Francisco. I've previously released several romance visual novels downloaded by thousands of readers (<i>Alloys Over Flowers</i>, <i>Criminally Overdue</i>), and am now working on my ninth novel. When I'm not writing, you can find me trying out new boba cafes across the city. 
          </p>
        </section>
        
        <section id="books">
          <div className="section-description">
            <h2>
              BOOKS
            </h2>
            <p>
              To date, none of my eight full-length novels have been published, as the majority of them were written as part of <a href="https://nanowrimo.org/national-novel-writing-month" target="_blank" rel="noopener noreferrer">NaNoWriMo</a> and have not gone through extensive revisions. Aside from applying what I've learned to write my next novels, I'm also trying to grow my novel editing skills by revising my older books. Below are several of my more enjoyable novels with a brief synopsis of each.
            </p>
          </div>

          <div className="book-section">
            <h3>
              Alloys Over Flowers
            </h3>
            <p><i>Fantasy Romance</i></p>
            <p>
              In an alternate 19th century, enthusiastic sculptor Junia has settled into a small town after accepting that her amnesia cannot be cured. Her life is uprooted when a young lord kidnaps her from her own home, believing she is captive against her will. Shocked to find that his fiancée no longer remembers him, Owen vows to convince her to return home with him, even if they must forge a relationship from scratch.
            </p>
            <p>
              This is the full-length novel version of my visual novel <i>Alloys Over Flowers</i>, which was downloaded by 7,000+ readers, received 25+ positive reviews, and was translated into Spanish and Portuguese.
            </p>
          </div>
          
          <div className="book-section">
            <h3>
              Is It Bad to Pick Up a Fake Boyfriend In a Bar?
            </h3>
            <p><i>Contemporary Romance</i></p>
            <p>
              Lydia Sun is a struggling science fiction author who believes her awful romance subplots are her biggest problem. Since her parents' dysfunctional relationship doesn't provide much guidance, she goes on a mission to find low-effort love. Fortunately, her old classmate Nathan Yang is looking for just the same thing. To avoid a cross-country work transfer, he lied that he was settling down with his longtime fiancée—but when the whole company is invited to his coworker's wedding and he has no plus-one to bring, Lydia agrees to stand in as his fake wife-to-be.
            </p>
            <p>
              What starts as a one-night lie quickly spirals into a precarious long-term facade when fans recognize Lydia, Nathan's coworker catches on to their fake relationship, and the wedding ends in disaster... but it's not easy to throw off suspicion when the sparks keep flying between them.
            </p>
          </div>
          
          <div className="book-section">
            <h3>
              Red-Topped Roofs
            </h3>
            <p><i>Historical Fantasy</i></p>
            <p>
              In a matriarchal China of rare magic and internal dissent, imperial princess Ming Jin Zu has always been content to pursue her studies alone under the shadow of her older sister. Except when the reigning empress pushes a military tactician's title onto her, Jin Zu finds herself woefully underqualified to lead the elite platoon she's been handed. And, embarrassingly, her whole team knows it.
            </p>
            <p>
              But when the Mongol Empire invades for magical resources during Jin Zu's expedition, the fate of her family's dynasty hangs in the role she must play by using everything she has and leading her team to unravel the true cause of the war—even if it means she must take responsibility for the first time in her life.
            </p>
          </div>

        </section>
      </body>

    </div>
  );
}

export default App;
